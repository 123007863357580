<template>
  <div class="user-update-box">
    <confirm-box
      v-if="showConfirmModal"
      @confirm="confirmSave"
      @cancel="cancelSave">
      <p><strong style="font-size: 14px">{{ duplicatedUserName }}</strong>ユーザーに名寄せします。よろしいでしょうか</p>
    </confirm-box>
    <div class="title">
      <h1 class="text">基本情報</h1>
    </div>
    <div
      v-show="showDetailRedirect"
      class="btn-detail">
      <button
        :disabled="disableBtn"
        class="mdc-button -user-detail"
        @click="getDetailUser">詳細</button>
    </div>
    <div class="mdc-layout-grid user-update-box-contents -elevation1">
      <div class="inner">
        <div
          :class="{'-hasShowError': userClassErrorMessage}"
          class="item cell -span6desktop -span8tablet -span4mobile -userclass">
          <p
            :class="{'-hasShowError': userClassErrorMessage}"
            class="label -userclass">法人/個人<span class="caution -necessary">(必要)</span></p>
          <div class="field -userclass">
            <div class="checkbox">
              <div class="mdc-radio">
                <input
                  v-model.number="userEdit.userClass"
                  value="1"
                  type="radio"
                  class="nativecontrol">
              </div>
              <div class="value">法人</div>
              <div class="mdc-radio">
                <input
                  v-model.number="userEdit.userClass"
                  value="2"
                  type="radio"
                  class="nativecontrol">
              </div>
              <div class="value">個人</div>
            </div>
            <p
              v-show="userClassErrorMessage"
              class="userClassError">{{ userClassErrorMessage }}</p>
          </div>
        </div>
        <div class="item cell -span6desktop -span8tablet -span4mobile -postcode">
          <span class="label -postcode">郵便番号</span>
          <div class="mdc-text-field field -postcode -outlined">
            <input
              v-model="userPostcode"
              type="text"
              class="input"
              maxlength="8">
            <span
              v-show="postcodeError"
              class="error">{{ postcodeError }}</span>
          </div>
          <button
            class="mdc-button addresssearch"
            @click="getAddressByPostCode">住所検索</button>
        </div>
        <div
          class="item cell -span6desktop -span8tablet -span4mobile -name">
          <p
            :class="{ '-name': parseInt(userEdit.userClass) === 2, '-company': parseInt(userEdit.userClass) === 1 }"
            class="label -name">{{ userClassName[userEdit.userClass] || '名前' }}<span class="caution -necessary">(必要)</span></p>
          <div class="mdc-text-field field -name -outlined">
            <input
              v-model="userEdit.name"
              type="text"
              class="input">
            <span
              v-show="userNameError"
              class="error">{{ userNameError }}</span>
          </div>
        </div>
        <div class="item cell -span6desktop -span8tablet -span4mobile -address">
          <span class="label -address">住所</span>
          <div class="mdc-text-field field -outlined">
            <input
              v-model="userEdit.address"
              type="text"
              class="input">
          </div>
        </div>
        <div class="item cell -span6desktop -span8tablet -span4mobile -cautionlevel">
          <span class="label -cautionlevel">注意度</span>
          <div class="mdc-select field -outlined papp-select">
            <select
              v-model.number="userEdit.cautionLevel"
              class="nativecontrol">
              <option value="0">不明</option>
              <option value="1">通常</option>
              <option value="2">注意</option>
              <option value="3">要注意</option>
              <option :disabled="isDisabledCautionLevelToNotTradableUser" value="4">取引不可</option>
            </select>
          </div>
        </div>
        <div class="item cell -span6desktop -span8tablet -span4mobile -status">
          <span class="label -status">ステータス</span>
          <div class="mdc-select field -outlined papp-select">
            <select
              v-model.number="userEdit.status"
              class="nativecontrol"
              @change="changeStatus">
              <option value="0">無効</option>
              <option value="1">有効</option>
              <option value="2">名寄せ</option>
            </select>
          </div>
        </div>
        <div
          v-show="userEdit.status === 2"
          class="item cell -span6desktop -hideontabletandbelow">
        </div>
        <div
          v-show="userEdit.status === 2"
          class="item cell -span6desktop -span8tablet -span4mobile -duplicateduser">
          <span
            data-tooltip="ユーザー情報ページのユーザー名の後ろに書いてあるユーザーIDということです"
            data-tooltip-position="top"
            class="label -duplicateduser">
            名寄せ先ID
            <img
              :src="infoImg"
              class="img">
          </span>
          <div class="duplicateduser-info">
            <div
              class="mdc-text-field field -outlined">
              <input
                v-model.number="userEdit.duplicatedUser"
                type="number"
                class="input"
                aria-controls="duplicateduser-helper-text"
                aria-describedby="duplicateduser-helper-text"
                maxlength="11"
                min="0"
                @change="checkDuplicatedUser">
              <span
                v-show="duplicatedUserError"
                class="error">{{ duplicatedUserError }}</span>
            </div>
            <n-link
              v-if="duplicatedUserId"
              id="duplicateduser-helper-text"
              :to="`${duplicatedUserId}`"
              class="mdc-text-field-helper-text -persistent"
              aria-hidden="true"
              target="_blank"
              v-html="duplicatedUserInfo"/>
          </div>
          <button
            class="mdc-button"
            @click="checkDuplicatedUser">名寄せ先のユーザーを確認する</button>
        </div>
        <div class="item cell -span12desktop -span8tablet -span4mobile -memo">
          <span class="label -memo">メモ</span>
          <div class="mdc-text-field field -memo -outlined">
            <textarea
              id="textarea"
              v-model="userEdit.memo"
              rows="5"
              class="input">
            </textarea>
          </div>
        </div>
        <div class="item cell -span6desktop -span8tablet -span4mobile -group">
          <span class="label -group">グループ設定</span>
          <user-group-name-input
            :init-group-id="userEdit.userGroupId"
            :create-group-error="createGroupError"
            @on-input-new-group-info="onInputNewGroupInfo"
            @on-select-group="onSelectGroup"/>
        </div>
        <div class="item cell -span6desktop -span8tablet -span4mobile -usertype">
          <span class="label -usertype">属性</span>
          <div class="mdc-select field -outlined papp-select">
            <select
              v-model="userEdit.userType"
              class="nativecontrol"
            >
              <option
                v-for="userTypeEntry in UserType.entries"
                :key="userTypeEntry.value"
                :value="userTypeEntry.value"
              >
                {{ userTypeEntry.label }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="isUserEditPageOpen && !isViewBiztelPage"
          class="item cell -span6desktop -span8tablet -span4mobile -compliance-checked"
        >
          <span class="label -compliance-checked">反社チェック</span>
          <div class="wrapper">
            <div
              v-for="(item, index) in complianceUrls"
              :key="`input-${index}`"
              class="urls"
            >
              <div class="mdc-text-field field -compliance-checked -outlined">
                <input
                  v-model="item.complianceUrl"
                  :disabled="!isComplianceCheckedUpdatable || !item.isNew"
                  type="text"
                  class="input"
                  @input="setUserEditComplianceUrls"
                >
                <p
                  v-show="item.isNew && isExistedComplianceUrl(item.complianceUrl)"
                  class="error"
                >
                  URLが登録されました
                </p>
                <span
                  v-if="!isComplianceCheckedUpdatable"
                  class="tooltip -bottom"
                >
                  反社チェック情報の紐づけは掲載担当に依頼してください。　<br>
                  　反社チェックがされていない場合は先に「ROBOROBO」にて実施してください。
                </span>
              </div>
              <button
                :disabled="!isComplianceCheckedUpdatable"
                class="mdc-button -delete"
                @click="deleteComplianceUrl(item.id, index)"
              >
                <!-- eslint-disable-next-line vue/html-self-closing-->
                <svg class="icon"></svg>削除
              </button>
            </div>
            <button
              :disabled="!isComplianceCheckedUpdatable"
              class="mdc-button -add"
              @click="addComplianceUrl"
            >
              <!-- eslint-disable-next-line vue/html-self-closing-->
              <svg class="icon"></svg>追加
            </button>
          </div>
        </div>
      </div>
      <div class="action">
        <button
          :disabled="disableSave || disableSaveForever || isSavingUserInfo"
          class="mdc-button savebtn"
          @click="handleClickSaveBtn">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify'
import { API_USERS_URL, API_USER_GROUP_URL } from '@/config/api-url'
import confirmBox from '@/components/confirm-box'
import userGroupNameInput from '@/components/user-page/user-group-name-input'
import { UserType, userConstants } from '@/config/user'

export default {
  components: {
    confirmBox,
    userGroupNameInput
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          id: 0,
          userClass: 1,
          userType: UserType.UNKNOWN,
          postcode: '',
          name: '',
          address: '',
          cautionLevel: 1,
          status: 1,
          memo: '',
          userGroupId: -1, // not belong to any group
          complianceCheckeds: []
        }
      }
    },
    showDetailRedirect: {
      type: Boolean,
      default: () => false
    },
    isViewBiztelPage: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      userEdit: {},
      userClassName: {
        1: '法人名',
        2: '個人名'
      },
      userClassErrorMessage: '',
      UserType,
      postcodeError: '',
      userNameError: '',
      disableSave: true,
      disableBtn: true,
      duplicatedUserInfo: '',
      duplicatedUserError: '',
      duplicatedUserName: '',
      duplicatedUserId: '',
      infoImg: require('../../assets/svg/baseline-info.svg'),
      showConfirmModal: false,
      newGroupInfo: {
        name: '',
        memo: ''
      },
      createGroupError: '',
      complianceUrls: [],
      isSavingUserInfo: false
    }
  },
  computed: {
    staffId: get('staffId'),
    staffEmail: get('auth@user.email'),
    isUserEditPageOpen: sync('ui/userEditPage@open'),
    isUserAddPageOpen: sync('ui/userAddPage@open'),
    inProgressCallHistory: sync('biztel/inProgressCallHistory/info'),
    biztelUserType: sync('biztel/user/currentType'),
    userPostcode: {
      get() {
        return this.userEdit.postcode
          ? this.userEdit.postcode.replace(/(\d{3})(\d+)/, '$1-$2')
          : this.userEdit.postcode
      },
      set(value) {
        this.userEdit.postcode = this.$helpers.zen2Han(value).replace('-', '')
      }
    },
    disableSaveForever() {
      return this.userInfo.status === 2
    },
    isComplianceCheckedUpdatable() {
      return userConstants.updatableComplianceCheckedMails.includes(
        this.staffEmail
      )
    },
    isDisabledCautionLevelToNotTradableUser() {
      const updatableCautionLevelToNotTradableUserEmails = [
        'kaho.tsuji@azoom.jp',
        'takumu.fujioka@azoom.jp',
        'le.thi.hoai.thuong@azoom.jp',
        'k.fukayama@azoom.jp'
      ]

      return !updatableCautionLevelToNotTradableUserEmails.includes(
        this.staffEmail
      )
    },
    hasDuplicateComplianceUrls() {
      return this.complianceUrls.some(({ complianceUrl }, _, arr) => {
        return (
          !!complianceUrl &&
          arr.filter(cu => cu.complianceUrl.trim() === complianceUrl.trim())
            .length > 1
        )
      })
    }
  },
  watch: {
    userInfo() {
      this.userEdit = { ...this.userInfo }
      this.cloneComplianceCheckeds()
      if (this.userEdit.status === 2) {
        this.isUserEditPageOpen = false
      }
      this.userClassErrorMessage = ''
      if (
        !this.userEdit.userClass &&
        this.userEdit.userClass !== 1 &&
        this.userEdit.userClass !== 2
      ) {
        this.userClassErrorMessage = '法人または個人を選択してください'
      }
    },
    isUserEditPageOpen(value) {
      if (value) {
        this.userEdit = { ...this.userInfo }
        this.duplicatedUserInfo = ''
        this.duplicatedUserError = ''
        this.postcodeError = ''
        this.userNameError = ''
      }
    },
    isUserAddPageOpen(value) {
      if (value) {
        this.userEdit = { ...this.userInfo }
        this.duplicatedUserInfo = ''
        this.duplicatedUserError = ''
        this.postcodeError = ''
        this.userNameError = ''
      }
    },
    userEdit: {
      handler() {
        let convertUserEdit = { ...this.userEdit }
        let convertUser = { ...this.userInfo }

        Object.keys(convertUserEdit).forEach(key => {
          convertUserEdit[key] =
            convertUserEdit[key] === null ? '' : convertUserEdit[key]
        })

        Object.keys(convertUser).forEach(key => {
          convertUser[key] = convertUser[key] === null ? '' : convertUser[key]
        })
        if (
          (!this.userEdit.userClass &&
            this.userEdit.userClass !== 1 &&
            this.userEdit.userClass !== 2) ||
          (!this.isViewBiztelPage && this.hasDuplicateComplianceUrls)
        ) {
          this.disableSave = true
        } else {
          this.disableSave =
            JSON.stringify(convertUserEdit) === JSON.stringify(convertUser)
        }

        if (this.userEdit.status !== 2) {
          this.duplicatedUserId = ''
          this.duplicatedUserError = ''
          this.duplicatedUserInfo = ''
          this.duplicatedUserName = ''
          this.userEdit.duplicatedUser = ''
        }
      },
      deep: true
    }
  },
  created() {
    this.userEdit = { ...this.userInfo }
    this.cloneComplianceCheckeds()
    if (
      !this.userEdit.userClass &&
      this.userEdit.userClass !== 1 &&
      this.userEdit.userClass !== 2
    ) {
      this.userClassErrorMessage = '法人または個人を選択してください'
    }
  },
  methods: {
    updateUserInfo: call('user/info/updateUserInfo'),
    createUserInfo: call('user/info/createUserInfo'),
    createUserTel: call('user/tels/createUserTel'),
    addUserComplianceCheck: call(
      'user/userComplianceCheck/addUserComplianceCheck'
    ),
    deleteUserComplianceCheck: call(
      'user/userComplianceCheck/deleteUserComplianceCheck'
    ),
    // ユーザー詳細ページに遷移する
    async getDetailUser() {
      this.$router.push({ path: `/users/${this.userEdit.id}` })
      this.isUserEditPageOpen = false
      this.isUserAddPageOpen = false
    },
    async getAddressByPostCode() {
      try {
        const geocodeRes = await this.$axios.get(
          `${window.location.protocol}//${
            window.location.host
          }/googleMaps/geocode`,
          {
            params: { address: this.userEdit.postcode }
          }
        )

        if (geocodeRes.data.results.length === 0) {
          this.postcodeError = '住所は見つかりません'
          this.userEdit.address = ''
          return
        }

        const addressObj = geocodeRes.data.results[0].address_components
          .filter(
            obj =>
              obj.types.includes('political') && !obj.types.includes('country')
          )
          .reverse()

        if (addressObj.length === 0) {
          this.postcodeError = '住所は見つかりません'
          this.userEdit.address = ''
          return
        }

        this.userEdit.address = addressObj.reduce(
          (address, obj) => `${address}${obj.long_name}`,
          ''
        )
        this.postcodeError = ''
      } catch (e) {
        this.$sentry.captureException(e)
      }
    },
    async saveUser() {
      try {
        await this.createNewGroup()
      } catch (error) {
        this.$sentry.captureException(error)
        return
      }
      let convertEditUser = {
        userId: this.userEdit.id,
        staffId: this.staffId,
        ...this.userEdit
      }

      Object.keys(convertEditUser).forEach(key => {
        convertEditUser[key] =
          convertEditUser[key] === null ? '' : convertEditUser[key]
      })
      if (convertEditUser.duplicatedUser == '') {
        delete convertEditUser.duplicatedUser
      }
      try {
        if (this.userEdit.id) {
          let promises = [this.updateUserInfo(convertEditUser)]
          if (
            this.isUserEditPageOpen &&
            !this.isViewBiztelPage &&
            this.isComplianceCheckedUpdatable &&
            JSON.stringify(this.userInfo.complianceCheckeds) !==
              JSON.stringify(this.userEdit.complianceCheckeds)
          ) {
            const {
              addedComplianceCheckList,
              deletedComplianceCheckIds
            } = this.parseComplianceCheckList()
            promises = [
              ...promises,
              ...(addedComplianceCheckList.length
                ? [
                    this.addUserComplianceCheck({
                      userId: this.userEdit.id,
                      complianceCheckList: addedComplianceCheckList
                    })
                  ]
                : ''),
              ...(deletedComplianceCheckIds.length
                ? [
                    this.deleteUserComplianceCheck({
                      userId: this.userEdit.id,
                      complianceCheckIds: deletedComplianceCheckIds
                    })
                  ]
                : '')
            ]
          }
          await Promise.all(promises)
          this.$emit('update-user-info', this.userEdit)
        } else {
          const userCreate = await this.createUserInfo(convertEditUser)
          this.$emit('create-user-info', userCreate)
          this.disableBtn = false
          if (this.$route.path.includes('biztel/callId')) {
            await this.createUserTel({
              userId: userCreate.id,
              tel: this.inProgressCallHistory.inProgressTel,
              staffId: this.staffId
            })
            this.inProgressCallHistory = {
              ...this.inProgressCallHistory,
              userId: userCreate.id
            }
          }
        }
      } catch (e) {
        this.$sentry.captureException(e)
      }
    },
    async handleClickSaveBtn() {
      this.isSavingUserInfo = true
      this.userNameError = !this.userEdit.name
        ? this.userClassName[this.userEdit.userClass] + 'を入力してください'
        : ''

      const updatingDuplicatedUser =
        this.userInfo.status !== 2 && this.userEdit.status === 2 ? true : false

      if (updatingDuplicatedUser) {
        await this.checkDuplicatedUser()
      }

      if (
        this.userNameError ||
        this.postcodeError ||
        this.duplicatedUserError
      ) {
        this.disableSave = true
        this.isSavingUserInfo = false
        return
      }

      if (updatingDuplicatedUser) {
        this.showConfirmModal = true
        this.isSavingUserInfo = false
        return
      }

      await this.saveUser()
      this.isSavingUserInfo = false
    },
    changeStatus() {
      if (this.userInfo.status !== 2 && this.userEdit.status === 2) {
        alert(
          `注意点：
            1.  名寄せしたいユーザーの情報は名寄せ先にまとめられます。
            　　・電話番号、メール、Fax、ユーザーStaff、住所
            　　・発着信履歴
            　　・問い合わせ案件
            　　・管理物件、オーナー物件
            　　・直営契約情報
            　　　等
            2.  名寄せした後は、以下のことが不可能になります。
            　　・名寄せのやり直し
            　　・名寄せ前のユーザー情報編集
            ※　間違って名寄せした場合、開発部に連絡してください！`
        )
      }
    },
    async checkDuplicatedUser() {
      this.duplicatedUserInfo = ''
      this.duplicatedUserError = ''

      if (!this.userEdit.duplicatedUser) {
        this.duplicatedUserError = '名寄せ先IDを入力してください'
        return
      }

      if (this.userInfo.id === this.userEdit.duplicatedUser) {
        this.duplicatedUserError =
          '名寄せしたいユーザーID以外を選択してください'
        this.disableSave = true
        return
      }

      try {
        const res = await this.$axios.get(
          `${API_USERS_URL}/${this.userEdit.duplicatedUser}`
        )
        if (!res.data || Object.keys(res.data).length === 0) {
          this.duplicatedUserError = '名寄せ先が見つかりません'
          this.disableSave = true
          return
        }

        if (res.data.status !== 1) {
          this.duplicatedUserError = '有効なユーザーを選択してください'
          this.disableSave = true
          return
        }

        if (res.data.name) {
          const label = res.data.userClass
            ? this.userClassName[res.data.userClass]
            : '名前'
          this.duplicatedUserInfo = `${label}： <span style="color: #42a5f5;">${
            res.data.name
          }<span>`
          this.duplicatedUserId = res.data.id
          this.duplicatedUserName = res.data.name
          return
        }
      } catch (e) {
        this.duplicatedUserError = 'エラーが発生しました'
        this.disableSave = true
        return
      }
    },
    async confirmSave() {
      await this.saveUser()
      this.showConfirmModal = false
    },
    cancelSave() {
      this.showConfirmModal = false
    },
    async createNewGroup() {
      if (this.newGroupInfo && this.newGroupInfo.name) {
        try {
          const response = await this.$axios.post(`${API_USER_GROUP_URL}`, {
            name: this.newGroupInfo.name,
            memo: this.newGroupInfo.memo || '',
            createdManagerId: this.staffId
          })
          if (response.data && response.data.id) {
            // set the response id to userEdit
            this.userEdit.userGroupId = response.data.id
            this.userEdit = { ...this.userEdit, userGroupId: response.data.id }
          } else {
            this.userEdit = { ...this.userEdit, userGroupId: -1 }
          }
          // TODO: improve: remove the redundant creatingNewGroup flag
          const { creatingNewGroup, ...userEdit } = this.userEdit
          this.userEdit = userEdit
        } catch (error) {
          this.createGroupError = 'ユーザーグループ情報が登録できませんでした。'
          // TODO: improve: remove the redundant creatingNewGroup flag
          const { creatingNewGroup, ...userEdit } = this.userEdit
          this.userEdit = userEdit
          throw error
        }
      }
    },
    onInputNewGroupInfo(group) {
      this.newGroupInfo = group
      if (group && group.name) {
        // TODO: improve: create new flag creatingNewGroup in userEdit object to enable the save button.
        this.userEdit = { ...this.userEdit, creatingNewGroup: true }
      } else {
        const { creatingNewGroup, ...userEdit } = this.userEdit
        this.userEdit = userEdit
      }
    },
    onSelectGroup(group) {
      if (group && group.id) {
        this.userEdit = { ...this.userEdit, userGroupId: group.id }
      } else {
        this.userEdit = { ...this.userEdit, userGroupId: -1 }
      }
    },
    cloneComplianceCheckeds() {
      this.complianceUrls = JSON.parse(
        JSON.stringify(this.userInfo.complianceCheckeds || [])
      )
    },
    addComplianceUrl() {
      const newComplianceUrl = { complianceUrl: '', isNew: true }
      this.complianceUrls = [newComplianceUrl, ...this.complianceUrls]
    },
    deleteComplianceUrl(complianceCheckId, index) {
      if (complianceCheckId) {
        this.complianceUrls = this.complianceUrls.filter(
          ({ id }) => id !== complianceCheckId
        )
      } else {
        this.complianceUrls.splice(index, 1)
      }
      this.setUserEditComplianceUrls()
    },
    setUserEditComplianceUrls() {
      this.userEdit.complianceCheckeds = [
        ...this.complianceUrls.filter(({ complianceUrl }) => !!complianceUrl)
      ]
    },
    isExistedComplianceUrl(url) {
      if (!url) return false

      return (
        this.complianceUrls.filter(
          ({ complianceUrl }) => complianceUrl.trim() === url.trim()
        ).length > 1
      )
    },
    parseComplianceCheckList() {
      const addedComplianceCheckList = this.userEdit.complianceCheckeds
        .filter(({ isNew }) => !!isNew)
        .map(({ complianceUrl }) => {
          return {
            userId: this.userEdit.id,
            complianceUrl,
            createdManagerId: this.staffId
          }
        })
      const restComplianceCheckIds = this.userEdit.complianceCheckeds
        .map(({ id }) => id)
        .filter(Boolean)
      const deletedComplianceCheckIds = this.userInfo.complianceCheckeds
        .filter(({ id }) => !!id && !restComplianceCheckIds.includes(id))
        .map(({ id }) => id)

      return {
        addedComplianceCheckList,
        deletedComplianceCheckIds
      }
    }
  }
}
</script>
